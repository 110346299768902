<template>

    <div class="mt-5">
        <button class="back-button mt-4 mb-4 mx-2" @click="goBack">
            <i class="fas fa-arrow-left"></i>
        </button>

        <div v-if="content.length != 0" class="text-center d-flex justify-content-center  content">
            <!-- show loading when loading is true -->




            <div class="col-10 col-lg-4 " v-if="loading != true">
                <img @click="showModal = true" :src="content.content_thumbnail" alt="Content Image" class="img-fluid"
                    style="cursor: pointer;">
            </div>

            <!-- Modal -->
            <div v-if="showModal" class="modal fade show d-block" tabindex="-1" role="dialog"
                aria-labelledby="modalLabel" aria-modal="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                @click="showModal = false">
                                <span aria-hidden="true">x</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <img :src="content.content_thumbnail" alt="Content Image" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>




<script>
import {
    core
} from '../../../config/pluginInit'
import Details from '../MovieDetailPage/Detail'
import BannerVideo from '../MovieDetailPage/BannerVideo'
import Items from '../Components/ListItems/ListItems'
import SkeletonLoader from '../Components/ListItems/ItemSkeleton'
import ApiService from '../../../services/api'
import AlertServie from '../../../services/errors'
export default {
    name: 'MovieDetail',
    components: {
        Details,
        Items,
        BannerVideo,
        SkeletonLoader
    },
    data() {
        return {
            showModal: false,
            content: {},
            categories: [],
            ContentID: this.$route.params.contentID,
            loading: true,
        }
    },
    methods: {

        goBack() {
            this.$router.go(-1);
        },
        openContent() {
            ApiService.openRamadanContent(this.$route.params.contentID).then((response) => {
                this.$store.commit('setPoints', response.data.user_points)
                this.content = response.data.data
                this.loading = false
            })
        },

        aletwithLang() {
            if (this.$i18n.locale === 'en') {
                AlertServie.info("to rewatch this content go to the (Ramadan) Section.")
            } else if (this.$i18n.locale === 'ar') {
                AlertServie.info("ولمشاهدة هذا المحتوى مرة أخرى، انتقل إلى قسم (رمضان).")
            } else {
                AlertServie.info("بۆ دووبارە سەیرکردنی ئەم ناوەڕۆکە بڕۆ بۆ بەشی (ڕەمەزان)")
            }
        }
    },
    mounted() {
        core.index()
        this.openContent()
        if (this.$previousRoute != '/ramadan') {
            this.aletwithLang()
        }


    },



}
</script>

<style>
.back-button {
    /* top: 20px;
    left: 20px;
    z-index: 1000; */
    background: #b16d08;
    color: #fff;
    border: none;
    padding: 9px 15px;
    zoom: 80%;
    border-radius: 50%;
    font-size: 20px;
    cursor: pointer;

}


.content {
    margin-top: 90px !important;
}

/* Ensure the image is responsive and centered */
.img-fluid {
    max-width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px !important;

}

.close {
    color: white !important;
    font-size: 70px !important;
    font-weight: 100 !important;
    text-shadow: none !important;
}


/*  add tranparent background for modal */
.modal {
    background: #141414 !important;
    z-index: 10000;
}

.modal-content {
    background-color: transparent !important;
    border: none !important;
}


.modal-header {
    border: none !important;
    background-color: transparent !important;
}
</style>