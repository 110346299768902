import Vue from 'vue'
import VueRouter from 'vue-router'
/* Layouts */
const FrontendLayout = () => import('../layouts/FrontendLayout')
const SubscribeLayout = () => import('../layouts/SubscribeLayout')

const LandingPage = () => import('../views/FrontendPages/MainPage.vue')
const MovieCatgeory = () => import('../views/FrontendPages/MovieCategory')
const MyVideos = () => import('../views/FrontendPages/MyVideos')
const MovieDetail = () => import('../views/FrontendPages/MovieDetail')

const RamadanLandingPage = () => import('../views/FrontendPages/ramadanLandingPage/index.vue')

import QuranHome from '../views/quran/index.vue'
import QuranView from '../views/quran/quran_view.vue'
import ramadanCategory from '../views/FrontendPages/ramadanLandingPage/ramadanCategoryDetail.vue'
import PrayerTimes from '../views/FrontendPages/ramadanLandingPage/prayerTime.vue'
import ramadanContent from '../views/FrontendPages/ramadanLandingPage/myRamadanListContent.vue'
import ramadanContentDetail from '../views/FrontendPages/ramadanLandingPage/ramadanContentDetail.vue'

/* subscribe pages */
const Login = () => import('../views/SubscribePages/login.vue')
const Verify = () => import('../views/SubscribePages/verify.vue')

Vue.use(VueRouter)

const landingPageRoutes = (prop) => [{
    path: '/',
    name: prop + '.landing-page',
    meta: {
      auth: false,
      name: 'landing page 1',
      slider: 'true'
    },
    component: LandingPage
  },
  {
    path: '/category/:category',
    name: prop + '.category',
    meta: {
      auth: true,
      name: 'Movie Category',
      slider: 'false'
    },
    component: MovieCatgeory
  },
  {
    path: '/detail/:contentID',
    name: prop + '.detail',
    meta: {
      auth: true,
      name: 'Movie Detail',
      slider: 'false'
    },
    component: MovieDetail
  },
  {
    path: '/myVideos',
    name: prop + '.myVideos',
    meta: {
      auth: true,
      name: 'MyVideos',
      slider: 'false'
    },
    component: MyVideos
  },
  {
    path: '/ramadan',
    name: 'ramadan',
    component: ramadanContent,
    meta: {
      auth: true
    },
  },
  {
    path: '/ramadan/detail/:contentID',
    name: 'ramadan-detail',
    component: ramadanContentDetail,
    meta: {
      auth: true
    },
  },
  {
    path: '/ramadan/category/:category',
    name: 'ramadan-category',
    component: ramadanCategory,
    meta: {
      auth: true
    },
  },
]

const subscribeRoutes = (prop) => [{
    path: '/',
    name: "/login page",
    meta: {
      auth: false,
      name: 'login',
      slider: 'true'
    },
    component: Login
  },
  {
    path: '/verify',
    name: "/verify page",
    meta: {
      auth: false,
      name: 'verify',
      slider: 'true'
    },
    component: Verify
  }
]

const routes = [{
    path: '/login',
    name: 'Login',
    component: SubscribeLayout,
    meta: {
      auth: false
    },
    children: subscribeRoutes("susbcribe")
  },
  {
    path: '/',
    name: 'landing-page',
    component: FrontendLayout,
    meta: {
      auth: false
    },
    children: landingPageRoutes('landing-page')
  },
  {
    path: '/ramadan-landing-page',
    name: 'ramadan-landing-page',
    component: RamadanLandingPage,
    meta: {
      auth: true
    },
  },
  {
    path: '/quran',
    name: 'quran',
    component: QuranHome,
    meta: {
      auth: true
    },
  },
  {
    path: '/quran/:id?',
    name: 'quran-view',
    component: QuranView,
    meta: {
      auth: true
    },
  },

  {
    path: '/ramadan/prayer-times',
    name: 'prayer-times',
    component: PrayerTimes,
    meta: {
      auth: true
    },
  },


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
})



router.beforeEach((to, from, next) => {
  Vue.prototype.$previousRoute = from.fullPath;
  next();
});


router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.auth)) {
      if (!localStorage.getItem('MyReligion')) {
        next("/login")
      } else {
        next()
      }
    } else {
      next()
    }
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
  }

)

export default router