<template>
    <main class="background_main ">

        <button class="back-button mt-4 mb-4 mx-2" @click="goBack">
            <i class="fas fa-arrow-left"></i>
        </button>


        <!-- add logo and text in center  -->
        <div class="text-center ">
            <img src="../../assets/images/quran_category.png" alt="logo" class="img-fluid"
                style="width: 100px;border-radius:20px">
        </div>


        <div class="row p-4">
            <div class="col-lg-4 col-12 col-md-6 text-center" v-for="surah in surahs.data">
                <router-link :to="{ path: '/quran/' + surah.number }" class="surah-link text-decoration-none">
                    <div class="item">
                        <div class="row">

                            <div class="col-9 main-color-one">
                                <h4 class="name ketab-font">{{ surah.name }}</h4>


                                <h6 class="num-of-ayahs d-inline-block main-color-two">عدد الآيات: <span>{{
                                    surah.numberOfAyahs
                                }}</span></h6>

                            </div>
                            <div class="col-3" style="align-self:center">
                                <h3 class="number">
                                    {{ surah.number }}
                                </h3>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </main>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;500;600;700&display=swap');
@import url('https://alquran.cloud/public/css/font-kitab.css?v=1');


@font-face {
    font-family: 'quran_font';
    src: url('../../assets/fonts/quran_fonts/ME_QURAN_VOLT_NEWMET.TTF') format('truetype');
    font-weight: bolder;
    font-style: normal;
}

body {
    font-family: 'Cairo', sans-serif !important;
    background: #0F172A !important;
}

.ketab-font {
    font-family: 'quran_font', sans-serif !important;
    font-size: 16px !important;
}


.num-of-ayahs {
    font-size: 0.8rem;
    font-weight: 500;
    font-family: 'Speda', sans-serif !important;
    margin-top: 0.5rem;
    color: #b16d08;
}


.header-text {
    font-size: 1.5rem;
    font-weight: 700;
    font-family: 'kitab', 'Cairo', sans-serif !important;
}

.main-color-one {
    color: #ffffff;
}

.main-color-two {
    color: #b16d08;
}

.main-bk {
    background-color: #ecf1f4;
}

.background_main {
    background: #0F172A !important;
}

.main-btn {
    outline: none;
    border: 1px solid #ecf1f4;
    background-color: #ecf1f4;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    font-size: .8rem;
    font-weight: 700;
    color: #41B883;
}

.current-playing-span {
    background-color: #41B883 !important;
}


.back-button {
    /* top: 20px;
    left: 20px;
    z-index: 1000; */
    background: #b16d08;
    color: #fff;
    border: none;
    padding: 9px 15px;
    zoom: 80%;
    border-radius: 50%;
    font-size: 20px;
    cursor: pointer;

}


/* start loader */
#loading {
    display: block;
    margin: auto;
    width: 50px;
    height: 50px;
    border: 3px solid #ecf1f4;
    border-radius: 50%;
    border-top-color: #41B883;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

/* end loader */





.surah-link .item {
    background-color: #1E293B;
    margin-bottom: 1.5rem;
    padding: 1rem 1rem 0.5rem 1rem;
    border-radius: 1rem;
    transition: .3s all ease-in-out;
}

.surah-link .item:hover {
    transform: scale(1.05);
}

.surah-link .item .number {
    width: 35px;
    height: 35px;
    background-color: #b16d08;
    /* Your background color */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    /* Text color */
    font-weight: bold;
    font-family: Arial, sans-serif;
    font-size: 16px !important;
}
</style>

<script>
import surahsJson from '../../assets/json/surahs.json';

export default {
    data() {
        return {
            surahs: surahsJson,
        }
    },

    methods: {
        goBack() {
            this.$router.push('/ramadan-landing-page');
        },
    }

}
</script>