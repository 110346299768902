<template>
    <!-- is mobile size show this section -->

    <div v-if="isMobile" class="background-image-mobile">

        <div class="container backbtn_container">
            <button class="back-button mt-4 mb-4 mx-2" @click="goBack">
                <i class="fas fa-arrow-left"></i>
            </button>
        </div>

        <div class="d-flex justify-content-center">
            <div class="col-12">
                <img style="width: -webkit-fill-available;" src="../../../assets/images/prayer_times.jpg"
                    class="img-responsive " alt="prayer-time">
            </div>
        </div>

    </div>

    <!-- end mobile section -->



    <!-- desktop size  -->
    <div v-else class="background-image-desktop ">

        <div class="background-image-mobile mobile-size-in-desktop  ">

            <div class="container backbtn_container">
                <button class="back-button mt-4 mb-4 mx-2" @click="goBack">
                    <i class="fas fa-arrow-left"></i>
                </button>
            </div>

            <div class="d-flex justify-content-center">
                <div class="col-12">
                    <img style="width: -webkit-fill-available;" src="../../../assets/images/prayer_times.jpg"
                        class="img-responsive " alt="prayer-time">
                </div>
            </div>


        </div>

    </div>
    <!-- end desktop size -->
</template>


<script>

export default {
    data() {
        return {
            // Initial window width
            activePrayer: "",
            categories: [],
            windowWidth: window.innerWidth,

        };
    },
    mounted() {
        window.addEventListener('resize', this.onResize);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        onResize() {
            this.windowWidth = window.innerWidth;
        },
        imageClicked(n) {
            alert(`Image ${n} clicked!`);
            // Implement your click handling logic here
        },
        goBack() {
            this.$router.push('/ramadan-landing-page');
        },


    },
    computed: {
        isMobile() {
            // Define a width threshold for mobile devices
            const mobileWidthThreshold = 768; // Adjust as needed
            return this.windowWidth <= mobileWidthThreshold;
        },



    },
};
</script>


<style scoped>
.background-image-mobile {
    /* Existing styles */
    background-image: url('../../../assets/images/Ramadanbg.png');
    background-size: cover;
    background-position: center;
    min-height: 100vh;

    /* Flexbox styles for alignment */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.background-image-desktop {
    /* Existing styles */
    background-image: url('../../../assets/images/ramadanDesktopBg.png');
    background-size: cover;
    background-position: center;
    min-height: 100vh;

    /* Flexbox styles for alignment */
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.back-button {
    /* top: 20px;
    left: 20px;
    z-index: 1000; */
    background: #b16d08;
    color: #fff;
    border: none;
    padding: 9px 15px;
    zoom: 80%;
    border-radius: 50%;
    font-size: 20px;
    cursor: pointer;

}

.backbtn_container {
    position: absolute;
    top: 0px !important;
}

.mobile-size-in-desktop {
    /* set a size of mobiloe size  */
    height: 100vh;
    max-width: 30%;
    margin: 0 auto;

}

.prayer-times {
    color: white;
    font-size: 14px;
    font-weight: bold;
    font-family: 'Speda' !important;
}

.active-prayer {
    color: #f1c40f !important;
}

.grid-layout .row {
    gap: 10px;
}

.square-image {
    /* Adjust as needed to ensure square images */
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.col-4:hover {
    cursor: pointer !important;
}
</style>
